var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-item-type",modifiers:{"modal-item-type":true}}],staticClass:"green-button-transparent mr-3 border-0",attrs:{"data-dashboard-step":"3"}},[_c('b-icon',{staticClass:"mr-2 top--3",attrs:{"icon":"plus-circle-fill","aria-hidden":"true","scale":"1"}}),_vm._v(" "+_vm._s(_vm.$t('webmag.item_add'))+" ")],1),_c('b-modal',{attrs:{"id":"modal-item-type","size":"lg","centered":"","no-stacking":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',[_vm._v(_vm._s(_vm.$t('webmag.item_add')))])]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"row"},[(_vm.$hasPrivileges(
            _vm.$privileges('CAN_CREATE_PROJECTS'),
            _vm.isSysAdmin,
            _vm.parentItem.privilegesForItem,
          ))?_c('div',{staticClass:"border-right-light-grey-md",class:(_vm.$hasPrivileges
            (_vm.$privileges('CAN_CREATE_PAGES'), _vm.isSysAdmin, _vm.parentItem.privilegesForItem)
            ) ? 'col-md-6' : 'col-md-12'},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-component-project",modifiers:{"modal-component-project":true}}],staticClass:"mx-auto mb-4 d-block light-grey-button",on:{"click":function($event){return _vm.setItem('project')}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus-circle-fill","aria-hidden":"true","scale":"1"}}),_vm._v(" "+_vm._s(_vm.$t('modals.createItem.chooseItemType.projectButton'))+" ")],1),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('modals.createItem.chooseItemType.projectButtonDesc'))}})],1):_vm._e(),(_vm.$hasPrivileges(
            _vm.$privileges('CAN_CREATE_PAGES'),
            _vm.isSysAdmin,
            _vm.parentItem.privilegesForItem,
          ))?_c('div',{staticClass:"select-page col-md-6",class:(_vm.$hasPrivileges(
            _vm.$privileges('CAN_CREATE_PROJECTS'), _vm.isSysAdmin, _vm.parentItem.privilegesForItem))
            ? 'col-md-6' : 'col-md-12'},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-component-page",modifiers:{"modal-component-page":true}}],staticClass:"mx-auto mb-4 d-block light-grey-button",on:{"click":function($event){return _vm.setItem('page')}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus-circle-fill","aria-hidden":"true","scale":"1"}}),_vm._v(" "+_vm._s(_vm.$t('modals.createItem.chooseItemType.pageButton'))+" ")],1),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('modals.createItem.chooseItemType.pageButtonDesc'))}})],1):_vm._e()])]},proxy:true},{key:"modal-footer",fn:function({ close }){return [_c('b-button',{staticClass:"p-3",attrs:{"block":""},on:{"click":function($event){return close()}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")])]}}])}),(_vm.$hasPrivileges(
      _vm.$privileges('CAN_CREATE_PROJECTS'),
      _vm.isSysAdmin,
      _vm.parentItem.privilegesForItem,
    ))?_c('modal-component-project',{attrs:{"item-position":_vm.folderProjectPosition,"child-slugs":_vm.childSlugs,"parent-id":_vm.parentItem.id,"parent-item":_vm.parentItem,"group-id":_vm.groupId,"group-slug":_vm.groupSlug,"group-domain":_vm.groupDomain,"group-path":_vm.groupPath,"email":_vm.email},on:{"insert-data":_vm.insertNewData}}):_vm._e(),(_vm.$hasPrivileges(
      _vm.$privileges('CAN_CREATE_PAGES'),
      _vm.isSysAdmin,
      _vm.parentItem.privilegesForItem,
    ))?_c('modal-component-page',{attrs:{"parent-item":_vm.parentItem,"item-position":_vm.pagePosition,"child-slugs":_vm.childSlugs,"group-id":_vm.groupId,"group-path":_vm.groupPath,"group-domain":_vm.groupDomain,"group-slug":_vm.groupSlug,"email":_vm.email}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }